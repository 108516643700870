<template>
  <div class="updateInfo">
    <!-- 顶部导航 -->
    <commonNavBar :title="updateInfo.name"></commonNavBar>
    <!-- 系统内容 -->
    <div
      class="infoBox"
      v-watermark
    >
      <p class="versionNumber">{{ updateInfo.versionNumber }}版本</p>
      <p class="createDatetime">{{ updateInfo.createDatetime | openYmdFormat }}</p>
      <p class="updateTitle">更新内容:</p>
      <van-field
        safe-area-inset-bottom
        v-model="updateInfo.description"
        autosize
        readonly
        type="textarea"
      />
    </div>
  </div>
</template>

<script>
import { getUpdateInfo } from '@/api/system'

export default {
  name: 'updateInfo',
  data() {
    return {
      updateInfo: {}
    }
  },
  async created() {
    try {
      const { data } = await getUpdateInfo(this.$route.params.id)
      if (data.data) {
        this.updateInfo = data.data
      } else {
        this.$toast.fail({
          message: '该系统版本已被删除!!',
          duration: 500
        })
        this.$router.back()
      }
    } catch (error) {
      this.$toast.fail({
        message: '该系统版本已被删除!!',
        duration: 500
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.updateInfo {
  ::v-deep {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;

    .infoBox {
      height: calc(100% - 46px);
      position: fixed;
      width: 100%;
      overflow: auto;
      padding-left: 10px;
      padding-right: 10px;

      .versionNumber {
        font-size: 16px;
        font-weight: 700;
        margin-top: 20px;
      }

      .createDatetime {
        font-size: 14px;
        color: #969799;
        margin: 10px 0;
      }

      .updateTitle {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .van-field {
        padding: 0;

        .van-field__control {
          color: #969799;
        }
      }

      .van-field__control {
        overflow: hidden;
      }

      .van-cell::after {
        border-bottom: 0;
      }
    }
  }
}
</style>
